import React, { useState, useContext } from "react";
import { AuthContext } from "../components/AuthProvider";

function Login() { 
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { user, login, logout, isUserLoggedIn, loginErrors } = useContext(AuthContext);
 
    return <div>
         {loginErrors && loginErrors.messages && loginErrors.messages.map((error, r)=>(
           <p key={r}>{error.message}</p>
         ))}
        <form className="max-w-xl mx-auto p-12" onSubmit={(e) => {e.preventDefault(); login(username, password)}}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700">
              Username
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="username" type="username" required   value={username}      onChange={(e) => setUsername(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          <div className="mt-6">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Pin code
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="password" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          
  
          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
                 
      <button  type="submit" value="Submit"
        className="font-display w-full items-center px-4 py-3 border border-transparent text-base mt-2 font-semibold rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
      >
        Login
      </button>


             
            </span>
          </div>
        </form>


    </div>
}

export default Login