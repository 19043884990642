import React, { useContext } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "./AuthProvider";

function PrivateRoute({ component: Component, location, ...rest }) {
  const { user, login, logout, isUserLoggedIn } = useContext(AuthContext);
 
  if (!isUserLoggedIn() && location.pathname !== `/account`) {
    console.log("isUserLoggedIn", isUserLoggedIn())
    navigate("/");
    return null;
  }

  return <Component {...rest} location={location} />;
}

export default PrivateRoute;
