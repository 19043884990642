import React, { useContext } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "./AuthProvider";

function PublicRoute({ component: Component, location, ...rest }) {
  const { user, login, logout, isUserLoggedIn } = useContext(AuthContext);

  if (
    isUserLoggedIn() &&
    (location.pathname == `/` || location.pathname == `/registration/`)
  ) {
    navigate("/account/dashboard");
    return null;
  }

  return <Component {...rest} />;
}

export default PublicRoute;
