import React, { useContext } from "react";
import { Router } from "@reach/router";
import Login from "../../views/login";
import AuthLayout from "../../components/AuthLayout"; 
import PrivateRoute from "../../components/PrivateRoute";
import PublicRoute from "../../components/PublicRoute";
import { AuthProvider, AuthContext } from "../../components/AuthProvider";

import Loadable from "react-loadable";

import Loading from '../../components/Loading'

const AsyncProfile = Loadable({
  loader: () => import("../../views/profile"),
  loading: Loading,
});

const AsyncLoggedInButNotRegisteredForEvent = Loadable({
  loader: () => import("../../views/LoggedInButNotRegisteredForEvent"),
  loading: Loading,
});

const AsyncDashboard = Loadable({
  loader: () => import("../../views/dashboard"),
  loading: Loading,
});
 
const AsyncSchedule = Loadable({
  loader: () => import("../../views/schedule"),
  loading: Loading,
});
 
const AsyncAskTheExperts = Loadable({
  loader: () => import("../../views/ask-the-experts"),
  loading: Loading,
});

const AsyncClassDetails = Loadable({
  loader: () => import("../../views/session-details"),
  loading: Loading,
});

const AsyncAskTheExpertsDetails = Loadable({
  loader: () => import("../../views/ask-the-experts-details"),
  loading: Loading,
});

const AsyncHelpDesk = Loadable({
  loader: () => import("../../views/help-desk"),
  loading: Loading,
});



const App = ({location}) => { 


  return (
    <div>
      <AuthProvider>
      <AuthLayout bg="gray" location={location}> 
        <Router basepath="/account">
          <PrivateRoute path="/profile" component={AsyncProfile} />
          <PrivateRoute path="/dashboard" component={AsyncDashboard} />
          <PrivateRoute path="/register" component={AsyncLoggedInButNotRegisteredForEvent} />
          <PrivateRoute path="/classes" component={AsyncSchedule} />
          <PrivateRoute path="/ask-the-experts" component={AsyncAskTheExperts} />
          <PrivateRoute path="/classes/:id" component={AsyncClassDetails} />
          <PrivateRoute path="/ask-the-experts/:id" component={AsyncAskTheExpertsDetails} askTheExperts={true} />
          <PrivateRoute path="/help-desk" component={AsyncHelpDesk} /> 
          <PublicRoute path="/" component={Login} />
        </Router>
        </AuthLayout>
      </AuthProvider>
    </div>
  );
};

export default App;
